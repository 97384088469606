.navbar-style{
  background-color: rgb(247, 217, 178);
}

.logo-image-style{
  width: 80px;
  height: 70px;
}

.logo-text-style{
  margin: -39px 0px 0px 82px;
  font-size: 22px;
}

marquee{
  margin-top: 1rem;
  font-size: 1.5rem;
}

main{
  min-height: 82vh;
}

.p-style{
  text-align: justify;
}

footer{
  background-color: rgb(247, 217, 178);
  padding: 10px;
  margin-top: 1.2rem;
}

*{
  font-family: 'Times New Roman', Times, serif;
}

tr,th{
  text-align: center;
}